{
  "ZeitplanCdkStack": {
    "HostedUiDomain": "https://zeitplan.auth.us-east-1.amazoncognito.com",
    "userpoolid": "us-east-1_GiYnFSUmL",
    "interval": "30",
    "webclientid": "7o95685e43ki0h7kud5nkp0j0r",
    "graphqlapiendpoint": "https://jp6r5ko4dncehndvraphwgkqde.appsync-api.us-east-1.amazonaws.com/graphql",
    "region": "us-east-1",
    "HostedSiteUrl": "www.zeitplan-app.com"
  }
}
